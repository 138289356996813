import { PlacementsSection } from 'components/bricks/types/placement.type';
import Translation from 'components/data/Translation';
import facebookFeed from '../../../../../components/shared/components/placements-block/images/facebook-feed.png';
import facebookProfileFeed from '../../../../../components/shared/components/placements-block/images/facebook-profile-feed.png';
import facebookVideoFeeds from '../../../../../components/shared/components/placements-block/images/facebook-video-feeds.png';
import facebookSearch from '../../../../../components/shared/components/placements-block/images/facebook-search.png';
import facebookInstreamVideo from '../../../../../components/shared/components/placements-block/images/facebook-instream-video.png';
import facebookMarketplace from '../../../../../components/shared/components/placements-block/images/facebook-marketplace.png';
import facebookReels from '../../../../../components/shared/components/placements-block/images/facebook-reels.png';
import facebookStory from '../../../../../components/shared/components/placements-block/images/facebook-story.png';
import instagramFeed from '../../../../../components/shared/components/placements-block/images/instagram-feed.png';
import instagramProfileFeed from '../../../../../components/shared/components/placements-block/images/instagram-profile-feed.png';
import instagramExploreHome from '../../../../../components/shared/components/placements-block/images/instagram-explore-home.png';
import instagramReels from '../../../../../components/shared/components/placements-block/images/instagram-reels.png';
import instagramStory from '../../../../../components/shared/components/placements-block/images/instagram-story.png';
import facebookRightHandColumn from '../../../../../components/shared/components/placements-block/images/faceboook-right-hand-column.png';

const placements: PlacementsSection[] = [
    {
        key: 'feed',
        label: Translation.get('adSetup.placements.feed', 'bricks'),
        children: [
            {
                recommendedHeight: 1,
                minHeight: 600,
                recommendedWidth: 1,
                minWidth: 600,
                type: 'feed',
                key: 'facebook-feed',
                label: 'Facebook Feed',
                platform: 'meta',
                channel: 'facebook',
                frameWidth: 340,
                multiFrame: true,
                icon: 'home',
                description: Translation.get('adSetup.placements.facebookFeedDescription', 'bricks'),
                previewImage: facebookFeed
            },
            {
                recommendedHeight: 1,
                recommendedWidth: 1,
                minWidth: 500,
                type: 'feed',
                key: 'instagram-stream',
                label: 'Instagram Feed',
                platform: 'meta',
                channel: 'instagram',
                frameWidth: 340,
                multiFrame: false,
                icon: 'home',
                description: Translation.get('adSetup.placements.instagramFeedDescription', 'bricks'),
                previewImage: instagramFeed
            },
            {
                recommendedHeight: 1,
                minHeight: 600,
                recommendedWidth: 1,
                minWidth: 600,
                type: 'feed',
                key: 'facebook-profile_feed',
                label: 'Facebook Profile Feed',
                platform: 'meta',
                channel: 'facebook',
                frameWidth: 340,
                multiFrame: false,
                icon: 'account_circle',
                description: Translation.get('adSetup.placements.facebookProfileFeedDescription', 'bricks'),
                previewImage: facebookProfileFeed
            },
            {
                recommendedHeight: 1,
                recommendedWidth: 1,
                minWidth: 500,
                type: 'feed',
                key: 'instagram-profile_feed',
                label: 'Instagram Profile Feed',
                platform: 'meta',
                channel: 'instagram',
                frameWidth: 340,
                multiFrame: false,
                icon: 'account_circle',
                description: Translation.get('adSetup.placements.instagramProfileFeedDescription', 'bricks'),
                previewImage: instagramProfileFeed
            },
            {
                recommendedHeight: 1,
                recommendedWidth: 1,
                minWidth: 500,
                type: 'feed',
                key: 'instagram-explore',
                label: 'Instagram Feed',
                platform: 'meta',
                channel: 'instagram',
                frameWidth: 340,
                multiFrame: true,
                icon: 'home',
                description: Translation.get('adSetup.placements.instagramExploreDescription', 'bricks'),
                previewImage: instagramFeed
            },
            {
                recommendedHeight: 1,
                recommendedWidth: 1,
                minWidth: 500,
                type: 'feed',
                key: 'instagram-explore_home',
                label: 'Instagram Explore Home',
                platform: 'meta',
                channel: 'instagram',
                frameWidth: 112,
                multiFrame: false,
                icon: 'explore',
                description: Translation.get('adSetup.placements.instagramExploreHomeDescription', 'bricks'),
                previewImage: instagramExploreHome
            },
            {
                recommendedHeight: 1,
                recommendedWidth: 1,
                minWidth: 500,
                type: 'feed',
                key: 'instagram-ig_search',
                label: 'Instagram Search',
                platform: 'meta',
                channel: 'instagram',
                frameWidth: 223,
                multiFrame: false,
                icon: 'manage_search',
                description: Translation.get('adSetup.placements.instagramSearchDescription', 'bricks'),
                previewImage: facebookSearch
            },
            {
                recommendedHeight: 1,
                minHeight: 600,
                recommendedWidth: 1,
                minWidth: 600,
                type: 'feed',
                key: 'facebook-video_feeds',
                label: 'Facebook Video Feeds',
                platform: 'meta',
                channel: 'facebook',
                frameWidth: 340,
                multiFrame: false,
                icon: 'subscriptions',
                description: Translation.get('adSetup.placements.facebookVideoFeedsDescription', 'bricks'),
                previewImage: facebookVideoFeeds
            },
            {
                recommendedHeight: 1,
                minHeight: 600,
                recommendedWidth: 1.91,
                minWidth: 600,
                type: 'feed',
                key: 'facebook-instream_video',
                label: 'Facebook Instream Video',
                platform: 'meta',
                channel: 'facebook',
                frameWidth: 340,
                multiFrame: false,
                icon: 'smart_display',
                description: Translation.get('adSetup.placements.facebookInstreamVideoDescription', 'bricks'),
                previewImage: facebookInstreamVideo
            },
            {
                recommendedHeight: 1,
                recommendedWidth: 1,
                minWidth: 500,
                type: 'feed',
                key: 'facebook-search',
                label: 'Facebook Search',
                platform: 'meta',
                channel: 'facebook',
                frameWidth: 300,
                multiFrame: false,
                icon: 'manage_search',
                description: Translation.get('adSetup.placements.facebookSearchDescription', 'bricks'),
                previewImage: facebookSearch
            },
            {
                recommendedHeight: 1,
                recommendedWidth: 1,
                type: 'feed',
                key: 'facebook-marketplace',
                label: 'Facebook Marketplace',
                platform: 'meta',
                channel: 'facebook',
                frameWidth: 154,
                multiFrame: false,
                icon: 'storefront',
                description: Translation.get('adSetup.placements.facebookMarketplaceDescription', 'bricks'),
                previewImage: facebookMarketplace
            },
            {
                recommendedHeight: 1,
                minHeight: 133,
                recommendedWidth: 1,
                minWidth: 254,
                type: 'feed',
                key: 'facebook-right_hand_column',
                label: 'Facebook Right Hand Column',
                platform: 'meta',
                channel: 'facebook',
                frameWidth: 140,
                multiFrame: false,
                icon: 'splitscreen_right',
                description: Translation.get('adSetup.placements.facebookRightHandColumnDescription', 'bricks'),
                previewImage: facebookRightHandColumn
            }
        ]
    },
    {
        key: 'story',
        label: Translation.get('adSetup.placements.story', 'bricks'),
        children: [
            {
                recommendedHeight: 16,
                recommendedWidth: 9,
                minWidth: 500,
                type: 'story',
                key: 'facebook-story',
                label: 'Facebook Story',
                platform: 'meta',
                channel: 'facebook',
                frameWidth: 340,
                multiFrame: true,
                icon: 'auto_stories',
                description: Translation.get('adSetup.placements.facebookStoryDescription', 'bricks'),
                previewImage: facebookStory
            },
            {
                recommendedHeight: 16,
                recommendedWidth: 9,
                minWidth: 500,
                type: 'story',
                key: 'facebook-facebook_reels',
                label: 'Facebook Reels',
                platform: 'meta',
                channel: 'facebook',
                frameWidth: 340,
                multiFrame: false,
                icon: 'animated_images',
                description: Translation.get('adSetup.placements.facebookReelsDescription', 'bricks'),
                previewImage: facebookReels
            },
            {
                recommendedHeight: 16,
                recommendedWidth: 9,
                minWidth: 500,
                type: 'story',
                key: 'instagram-story',
                label: 'Instagram Story',
                platform: 'meta',
                channel: 'instagram',
                frameWidth: 340,
                multiFrame: true,
                icon: 'auto_stories',
                description: Translation.get('adSetup.placements.instagramStoryDescription', 'bricks'),
                previewImage: instagramStory
            },
            {
                recommendedHeight: 16,
                recommendedWidth: 9,
                minWidth: 500,
                type: 'story',
                key: 'instagram-reels',
                label: 'Instagram Reels',
                platform: 'meta',
                channel: 'instagram',
                frameWidth: 340,
                multiFrame: false,
                icon: 'animated_images',
                description: Translation.get('adSetup.placements.instagramReelsDescription', 'bricks'),
                previewImage: instagramReels
            }
        ]
    }
];

export default placements;
